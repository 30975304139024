import React, { FC } from 'react';
import clsx from 'clsx';
import { IMenuTypes } from 'utils/models';
import useAuth from 'hooks/useAuth';

// components
import SideActions from './SideActions';
import {
  SvgSideConsultations,
  SvgSideCoupon,
  SvgSideDoctor,
  SvgSidePatient,
  SvgSidePrescription,
  SvgSideProducts
} from './SvgIcons';
import { ClipboardListIcon } from '@heroicons/react/outline';
import { UserIcon } from '@heroicons/react/solid';

const SideBar: FC = () => {
  const { user } = useAuth();

  const links: IMenuTypes[] = [];

  if (
    user?.is_super_admin ||
    user?.user_type?.patient_access ||
    user?.user_type?.login_patient_access
  ) {
    links.push({
      icon: <SvgSidePatient className="w-5 h-5" />,
      label: 'Patients',
      to: '/patients'
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.doctor_access ||
    user?.user_type?.login_doctor_access
  ) {
    links.push({
      icon: <SvgSideDoctor className="w-5 h-5" />,
      label: 'Doctors',
      to: '/doctors'
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.consultation_access ||
    user?.user_type?.reset_consultation_access ||
    user?.user_type?.generate_prescription_access ||
    user?.user_type?.reschedule_access ||
    user?.user_type?.did_not_attend_access ||
    user?.user_type?.cancel_access ||
    // @TODO
    user?.user_type?.consultation_logs_access
  ) {
    links.push({
      icon: <SvgSideConsultations className="w-5 h-5" />,
      label: 'Consultations',
      to: '/consultations'
    });
  }

  if (user?.is_super_admin || user?.user_type?.product_access) {
    // links.push({
    //   icon: <SvgSideProducts className="w-5 h-5" />,
    //   label: 'Products',
    //   to: '/products'
    // });

    links.push({
      icon: <SvgSideProducts className="w-5 h-5" />,
      label: 'Product Management',
      actions: [
        {
          label: 'Product List',
          to: '/products'
        },
        {
          label: 'Product Management',
          to: '/products/management'
        }
      ]
    });
  }

  if (
    user?.is_super_admin ||
    user?.user_type?.prescription_nova_access ||
    user?.user_type?.prescription_air_access
  ) {
    links.push({
      icon: <ClipboardListIcon className="w-5 h-5" />,
      label: 'Orders',
      to: '/orders'
    });
  }

  if (user?.is_super_admin || user?.user_type?.prescription_access) {
    links.push({
      icon: <SvgSidePrescription className="w-5 h-5" />,
      label: 'Prescriptions',
      to: '/prescriptions'
    });
  }

  if (user?.is_super_admin || user?.user_type?.coupon_access) {
    links.push({
      icon: <SvgSideCoupon className="w-5 h-5" />,
      label: 'Coupons',
      to: '/coupons'
    });
  }

  if (user?.is_super_admin) {
    links.push({
      icon: <UserIcon className="w-5 h-5" />,
      label: 'User Management',
      actions: [
        {
          label: 'User Access Management',
          to: '/users'
        },
        {
          label: 'User Types Management',
          to: '/user-types'
        },
        {
          label: 'Payment Transactions',
          to: '/payment-transactions'
        }
      ]
    });
  }

  return (
    <div className="hidden md:flex h-full justify-center items-center">
      <div
        className={clsx(
          'bg-polln-light-gray-200 h-[calc(100dvh-6rem)] border rounded-tr-lg rounded-br-lg'
        )}
      >
        <div className="my-6">
          <SideActions actions={links} isCollapse />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
